import { IconButton } from "monday-ui-react-core";
import { AddNewButton } from "../common/AddNewButton";
import { VerticalCard } from "../common/Card";
import { EditorHeader } from "../common/EditorHeader";
import { RemoveItemButton } from "../common/RemoveItemButton";
import { Copy, FileCsv, FileXls, X } from "@phosphor-icons/react";
import { TextInput } from "../attributes/Input";
import { UID } from "../../types/uid";
import {
  useColumnExport,
  useColumnFormat,
  useColumnFormats,
} from "../hooks/useColumnFormats";
import { useColumn } from "../hooks/useColumns";
import { SingleDropdown } from "../attributes/Dropdown";
import { downloadCSV, downloadExcelSheet } from "../util/exportFileUtil";
import {
  extraRecordAttributeOptions,
  useRecordAttributes,
} from "../hooks/useRecordAttributes";
import { useRecords } from "../hooks/useRecords";

const ColumnDefinition = ({
  className,
  columnFormatId,
  columnId,
}: {
  className?: string;
  columnFormatId: UID;
  columnId: UID;
}) => {
  const { name, attributeId, remove, rename, duplicate, changeAttributeId } =
    useColumn(columnFormatId, columnId);

  const { attributeOptions } = useRecordAttributes();

  return (
    <div
      className={`${className} flex items-center justify-between
    bg-slate-50 border-t border-slate-300`}
    >
      <div className="flex flex-wrap">
        <TextInput
          className="w-fit"
          initialValue={name}
          onChange={(newValue) => {
            rename(newValue);
          }}
        />
        <SingleDropdown
          selectedId={attributeId}
          possibleItems={[...extraRecordAttributeOptions, ...attributeOptions]}
          onChange={(id) => changeAttributeId(id)}
        />
      </div>
      <div className="flex">
        <IconButton
          icon={Copy}
          size={IconButton.sizes.XS}
          onClick={() => duplicate()}
        />
        <IconButton
          icon={X}
          size={IconButton.sizes.XS}
          onClick={() => remove()}
        />
      </div>
    </div>
  );
};

const ColumnFormat = ({
  className,
  columnFormatId,
}: {
  className?: string;
  columnFormatId: UID;
}) => {
  const { name, columnIds, duplicate, remove, rename, createColumn } =
    useColumnFormat(columnFormatId);

  const { computeUTMRecords } = useRecords();
  const { computeColumnExport } = useColumnExport();

  const exportFilename = name.replaceAll(" ", "_") || "export";

  return (
    <VerticalCard
      className={`${className} col-span-12`}
      header={
        <div className="flex justify-between">
          <TextInput
            className="w-full"
            initialValue={name}
            onChange={(newValue) => {
              rename(newValue);
            }}
          />
          <div className="flex items-center">
            <IconButton
              icon={Copy}
              onClick={() => duplicate()}
              size={IconButton.sizes.SMALL}
            />
            <RemoveItemButton
              onRemove={() => {
                remove();
              }}
            />
            <div className="flex items-center gap-1 ">
              <IconButton
                icon={() => <FileCsv size={24} />}
                size={IconButton.sizes.SMALL}
                color={IconButton.colors.BRAND}
                kind={IconButton.kinds.SECONDARY}
                onClick={() => {
                  downloadCSV(
                    exportFilename,
                    computeColumnExport(columnFormatId, computeUTMRecords()),
                    true,
                  );
                }}
              />
              <IconButton
                icon={() => <FileXls size={24} />}
                size={IconButton.sizes.SMALL}
                color={IconButton.colors.POSITIVE}
                kind={IconButton.kinds.SECONDARY}
                onClick={() =>
                  downloadExcelSheet(
                    exportFilename,
                    computeColumnExport(columnFormatId, computeUTMRecords()),
                    true,
                  )
                }
              />
            </div>
          </div>
        </div>
      }
    >
      <div className="w-full grid grid-cols-12 p-2">
        <div className="col-span-12">
          <div className="text-sm">Columns</div>
          <AddNewButton onSelect={() => createColumn()} />
          {columnIds.map((columnId: UID) => (
            <ColumnDefinition
              key={columnId}
              columnId={columnId}
              columnFormatId={columnFormatId}
            />
          ))}
        </div>
      </div>
    </VerticalCard>
  );
};

const ColumnsEditor = ({ className }: { className?: string }) => {
  const { columnFormatIds, create } = useColumnFormats();
  return (
    <div className={`${className} w-full overflow-y-scroll no-scrollbar pb-96`}>
      <div className="flex flex-col p-4 gap-2">
        <EditorHeader
          className=""
          title="Column Formats"
          subtitle="Specify column formats used in exports"
        ></EditorHeader>
        <AddNewButton className="" onSelect={() => create()} />
      </div>
      <div className="grid grid-cols-12 px-4 gap-2">
        {columnFormatIds.map((columnFormatId: UID) => (
          <ColumnFormat key={columnFormatId} columnFormatId={columnFormatId} />
        ))}
      </div>
    </div>
  );
};

export { ColumnsEditor };
