import { produce } from "immer";
import { UID } from "../../types/uid";
import { EventGroup, HandlerLookup, makeGeneralPayload } from "../eventUtil";
import { createAction } from "../userEventHandlers";

enum ColumnEventType {
  Create = "column/create",
  Rename = "column/rename",
  Delete = "column/delete",
  Duplicate = "column/duplicate",
  ChangeAttribute = "column/attributeId",
}

type ColumnEvent = EventGroup<{
  [ColumnEventType.Create]: {
    columnFormatId: UID;
    columnId: UID;
  };
  [ColumnEventType.Rename]: {
    columnFormatId: UID;
    columnId: UID;
    newName: string;
  };
  [ColumnEventType.Delete]: {
    columnFormatId: UID;
    columnId: UID;
  };
  [ColumnEventType.Duplicate]: {
    columnFormatId: UID;
    referenceColumnId: UID;
    newColumnId: UID;
  };
  [ColumnEventType.ChangeAttribute]: {
    columnFormatId: UID;
    columnId: UID;
    newAttributeId: UID | undefined;
  };
}>;

const columnHandlers: HandlerLookup<ColumnEvent> = {
  [ColumnEventType.Create]: {
    generalizer: ({ payload: { columnFormatId, columnId } }) =>
      makeGeneralPayload({
        payloadA: columnFormatId,
        payloadB: columnId,
      }),
    specifier: ({ payloadA, payloadB }) =>
      createAction[ColumnEventType.Create]({
        columnFormatId: payloadA,
        columnId: payloadB,
      }),
    reducer: (state, action) =>
      produce(state, (draftState) => {
        const { columnFormatId, columnId } = action.payload;
        draftState.columnFormats.definitions[columnFormatId].columnDefinitions[
          columnId
        ] = {
          name: "New column",
          attributeId: undefined,
        };
        draftState.columnFormats.definitions[
          columnFormatId
        ].columnOrdering.push(columnId);
      }),
    validator: (state, action) => {
      const { columnFormatId, columnId } = action.payload;
      return (
        columnFormatId in state.columnFormats.definitions &&
        !(
          columnId in
          state.columnFormats.definitions[columnFormatId].columnDefinitions
        )
      );
    },
  },
  [ColumnEventType.Rename]: {
    generalizer: ({ payload: { columnFormatId, columnId, newName } }) =>
      makeGeneralPayload({
        payloadA: columnFormatId,
        payloadB: columnId,
        payloadC: newName,
      }),
    specifier: ({ payloadA, payloadB, payloadC }) =>
      createAction[ColumnEventType.Rename]({
        columnFormatId: payloadA,
        columnId: payloadB,
        newName: payloadC,
      }),
    reducer: (state, action) =>
      produce(state, (draftState) => {
        const { columnFormatId, columnId } = action.payload;
        draftState.columnFormats.definitions[columnFormatId].columnDefinitions[
          columnId
        ].name = action.payload.newName;
      }),
    validator: (state, action) => {
      const { columnFormatId, columnId } = action.payload;
      return (
        columnFormatId in state.columnFormats.definitions &&
        columnId in
          state.columnFormats.definitions[columnFormatId].columnDefinitions
      );
    },
  },
  [ColumnEventType.Delete]: {
    generalizer: ({ payload: { columnFormatId, columnId } }) =>
      makeGeneralPayload({
        payloadA: columnFormatId,
        payloadB: columnId,
      }),
    specifier: ({ payloadA, payloadB }) =>
      createAction[ColumnEventType.Delete]({
        columnFormatId: payloadA,
        columnId: payloadB,
      }),
    reducer: (state, action) =>
      produce(state, (draftState) => {
        const { columnFormatId, columnId } = action.payload;
        delete draftState.columnFormats.definitions[columnFormatId]
          .columnDefinitions[columnId];
        draftState.columnFormats.definitions[columnFormatId].columnOrdering =
          state.columnFormats.definitions[columnFormatId].columnOrdering.filter(
            (columnId: UID) => action.payload.columnId !== columnId
          );
      }),
    validator: (state, action) => {
      const { columnFormatId, columnId } = action.payload;
      return (
        columnFormatId in state.columnFormats.definitions &&
        columnId in
          state.columnFormats.definitions[columnFormatId].columnDefinitions
      );
    },
  },
  [ColumnEventType.Duplicate]: {
    generalizer: ({
      payload: { columnFormatId, referenceColumnId, newColumnId },
    }) =>
      makeGeneralPayload({
        payloadA: columnFormatId,
        payloadB: referenceColumnId,
        payloadC: newColumnId,
      }),
    specifier: ({ payloadA, payloadB, payloadC }) =>
      createAction[ColumnEventType.Duplicate]({
        columnFormatId: payloadA,
        referenceColumnId: payloadB,
        newColumnId: payloadC,
      }),
    reducer: (state, action) =>
      produce(state, (draftState) => {
        const { columnFormatId, referenceColumnId, newColumnId } =
          action.payload;
        draftState.columnFormats.definitions[columnFormatId].columnDefinitions[
          newColumnId
        ] = {
          ...state.columnFormats.definitions[columnFormatId].columnDefinitions[
            referenceColumnId
          ],
        };
        draftState.columnFormats.definitions[
          columnFormatId
        ].columnOrdering.splice(
          draftState.columnFormats.definitions[
            columnFormatId
          ].columnOrdering.indexOf(referenceColumnId) + 1,
          0,
          newColumnId
        );
      }),
    validator: (state, action) => {
      const { columnFormatId, referenceColumnId, newColumnId } = action.payload;
      return (
        columnFormatId in state.columnFormats.definitions &&
        referenceColumnId in
          state.columnFormats.definitions[columnFormatId].columnDefinitions &&
        !(
          newColumnId in
          state.columnFormats.definitions[columnFormatId].columnDefinitions
        )
      );
    },
  },
  [ColumnEventType.ChangeAttribute]: {
    generalizer: ({ payload: { columnFormatId, columnId, newAttributeId } }) =>
      makeGeneralPayload({
        payloadA: columnFormatId,
        payloadB: columnId,
        payloadC: newAttributeId || "",
      }),
    specifier: ({ payloadA, payloadB, payloadC }) =>
      createAction[ColumnEventType.ChangeAttribute]({
        columnFormatId: payloadA,
        columnId: payloadB,
        newAttributeId: payloadC === "" ? undefined : payloadC,
      }),
    reducer: (state, action) =>
      produce(state, (draftState) => {
        const { columnFormatId, columnId } = action.payload;
        draftState.columnFormats.definitions[columnFormatId].columnDefinitions[
          columnId
        ].attributeId = action.payload.newAttributeId;
      }),
    validator: (state, action) => {
      const { columnFormatId, columnId } = action.payload;
      return (
        columnFormatId in state.columnFormats.definitions &&
        columnId in
          state.columnFormats.definitions[columnFormatId].columnDefinitions
      );
    },
  },
};

export { columnHandlers, ColumnEventType };

export type { ColumnEvent };
